import React from "react";

const MainContents = ({ leftColumnWidth }) => {
  return (
    <main className="px-4 py-2" style={{ marginLeft: leftColumnWidth }}>
      <h1>Architecting on AWS へようこそ！（<a href="https://aws.amazon.com/jp/training/classroom/architecting-on-aws/" target="blank">Architecting on AWS について</a>）</h1>
      <hr class="koji"/>
      <h3 id="prepare" class="anchor">初日の準備</h3>
      <div class="box">
        <h5>受講準備</h5>
        <p>
        ===== 受講環境について =====<br/>
        ・ブラウザは Chrome もしくは Firefox をお使いください。<br/>
        ・極力 VPN は切断してください。（通信が不安定になる可能性があります）<br/>
        ・本コースではマイクの使用は必須ではありません。スピーカー（やヘッドホンなど）を準備いただければ OK です。<br/>
        ・可能であればモニターは複数用意していただくと演習（ラボ）を効率的に進めることができます。（手順書と操作画面を行ったり来たりする為）<br/>
        ===== <a href="https://a.co/7SeNx7p" target="blank">【1】配信ツール（Webex）の使い方</a> =====<br/>
        ・音声が聞こえない方は P. 28 をご確認ください<br/>
        ===== <a href="https://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">【2】受講者ガイド利用手順</a> =====<br/>
        ・コース開始前に <a href="https://evantage.gilmoreglobal.com/" target="blank">こちら</a> からアカウントの作成をお願いします。<br/>
        ・講義で用いる教材は「Student Guide」、演習の手順書は「Lab Guide」となります。<br/>
        ・ただし、スライドの並び替えや取捨選択、補足資料の追加などをしておりますので、教材ではなく Webex の画面を見ながらの受講をお願いします。<br/>
        ・トレーニングの期間中に必ず 1 度は教材への閲覧をお願いします。<br/>
        ・教材は、引き換え後、720 日間閲覧可能です。<br/>
        ※ 稀に、引き換え後にガイドが表示されないことがあります。同期の問題であることが多いので、午後に [更新] ボタンを押してみてください。<br/>
        ====== 【3】受講者ポータルへのアクセス方法 =====<br/>
        ・ログインページは <a href="" target="blank">こちら(XXXXXXXX - XXXXXXXX)</a> です。<br/>
        ・受講者ポータルから、ラボ（ハンズオン）の実施や、教科書の閲覧ができます。<br/>
        ・研修を申し込む際に使用したアカウントでログインをお願いします。<br/>
        </p>
        <hr/>
        <h5>受講準備（Jam）</h5>
        <p>
        ===== 受講環境について =====<br/>
        ・同じくブラウザは Chrome もしくは Firefox をお使いください。<br/>
        ・極力 VPN は切断してください。（通信が不安定になる可能性があります）<br/>
        ・Jam はマイクの使用を推奨しております。コミュニケーションを円滑にするために使用下さい。<br/>
        ・可能であればモニターは複数用意していただくと「チャレンジ」を効率的に進めることができます。（手順書と操作画面を行ったり来たりする為）<br/>
        ===== <a href="https://a.co/7SeNx7p" target="blank">【1】配信ツール（Webex）の使い方</a> =====<br/>
        ・同じく音声が聞こえない方は P. 28 をご確認ください<br/>
        ====== 【2】登録に関する内容および Jam 用の補足資料に関して =====<br/>
        ・Jam への登録に関しては<a href="https://arch-appendix.s3.ap-northeast-1.amazonaws.com/Jam_pre_register.pdf" target="blank">こちら（AWS Jam アカウント登録案内）</a>の資料をご参照下さい。<br/>
        ・上記につきましてまずはログインが必要になりますので講義内「受講者ポータル」にアクセス頂いた方法と同じ方法でログインの上、登録を進行して下さい。（基本「Login with Amazon」にて）<br/>
        ・流れとしては　＜登録案内にある register URL へのアクセス＞→＜画面中央「AWS スキルビルダーでログイン」からログイン、「受講者ポータル」利用の際と同様で。＞→＜再度 1 点目の register URL へ＞<br/>
        ・Jam のオリエンテーション時にご案内している資料の抜粋版は<a href="https://arch-appendix.s3.ap-northeast-1.amazonaws.com/Jam_Appendix.pdf" target="blank">こちら</a>になります。ダウンロードなど頂き、適宜ご確認下さい。<br/>
        </p>
        <hr/>
        <h5>Google Slides へアクセス</h5>
        <p>
          本トレーニングではグループワークを用意しています。その際にGoogle Slides を利用します。<br/>
          下記URLへアクセスできるかご確認ください。 (確認用の為、下記 URL は本利用しません。)<br/>
          <a href="https://docs.google.com/presentation/d/1teOZTSorFLgsIENdOp_rhYPYI6_PAMkfvLZeWr7883Y/edit?usp=sharing" target="blank">グループワーク資料 (Google Slides)</a>
          <div class="blackboard-box">
            <p><b><span class="warn">セキュリティによりアクセスできない場合は Rocket Chat へその旨投稿してください</span></b></p>
            <div class="chalk1"></div>
            <div class="chalk2"></div>
          </div>
        </p>
        <hr/>
        <h5>付加資料に関して</h5>
        <p>
          本トレーニング内で適宜まとめております資料につきましては別途 PDF にて切り出しております。 (転送等はお控え下さいますよう、お願い致します。)<br/>
          <a href="https://arch-appendix.s3.ap-northeast-1.amazonaws.com/ArchitectingOnAWS_Module_Appendix_7.5.pdf" target="blank">トレーニング付加資料 v7.5 (PDF)</a><br/>
          <a href="https://arch-appendix.s3.ap-northeast-1.amazonaws.com/ArchitectingOnAWS_Lab_Appendix.pdf" target="blank">トレーニング付加資料 ラボ (PDF)</a>
        </p>
        
      </div>
      <hr class="koji"/>
      <h3 id="agenda" class="anchor">アジェンダ</h3>
      <div class="box">
        <div class="blackboard-box">
          <p><b><span class="warn">下記はあくまで予定なので参考程度にお考えください</span></b></p>
          <p>みなさんが手を動かす時間を多めに取る予定です</p>
          <div class="chalk1"></div>
          <div class="chalk2"></div>
        </div>
        <h5>Day 1</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>1</td>
            <td>アーキテクチャの設計の基礎</td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>アカウントのセキュリティ</td>
            <td><span class="point_box"><b>ラボ1</b></span>AWS マネジメントコンソールと AWS Command Line Interface の確認と操作</td>
          </tr>
          <tr>
            <td>3</td>
            <td>ネットワーク1</td>
            <td></td>
          </tr>
          <tr>
            <td>4</td>
            <td>コンピューティング</td>
            <td><span class="point_box"><b>ラボ2</b></span>Amazon VPC インフラストラクチャを構築する</td>
          </tr>
          <tr>
            <td>5</td>
            <td>ストレージ</td>
            <td></td>
          </tr>
        </table>
        <hr/>
        <h5>Day 2</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>6</td>
            <td>データベースサービス</td>
            <td><span class="point_box"><b>ラボ3</b></span>Amazon VPC インフラストラクチャでのデータベースレイヤーの作成</td>
          </tr>
          <tr>
            <td>7</td>
            <td>モニタリングとスケーリング</td>
            <td><span class="point_box"><b>ラボ4</b></span>Amazon VPC で高可用性を構成する</td>
          </tr>
          <tr>
            <td>8</td>
            <td>オートメーション</td>
            <td></td>
          </tr>
          <tr>
            <td>10</td>
            <td>ネットワーク2</td>
            <td></td>
          </tr>
          <tr>
            <td>11a</td>
            <td>サーバーレス（前半 SQS / SNS）</td>
            <td></td>
          </tr>
        </table>
        <hr/>
        <h5>Day 3</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>9</td>
            <td>コンテナ</td>
            <td></td>
          </tr>
          <tr>
            <td>11b</td>
            <td>サーバーレス（後半 Lambda　等）</td>
            <td><span class="point_box"><b>ラボ5</b></span>サーバーレスアーキテクチャを構築する</td>
          </tr>
          <tr>
            <td>12</td>
            <td>エッジサービス</td>
            <td><span class="point_box"><b>ラボ6</b></span>Amazon S3 オリジンで Amazon CloudFront ディストリビューションを設定する</td>
          </tr>
          <tr>
            <td>13</td>
            <td>バックアップと復旧</td>
            <td></td>
          </tr>
          <tr>
            <td>グループワーク</td>
            <td>既存のアプリケーションのフォークリフト</td>
            <td><a href="#groupwork">進行手順の補足</a></td>
          </tr>
          <tr>
            <td>エンディング</td>
            <td>コースのまとめ＆アンケート入力</td>
            <td></td>
          </tr>
        </table>
      </div>
      <hr class="koji"/>
      <h3 id="modules" class="anchor">参考リンク</h3>
      <div class="box">
        <h5>モジュール1（アーキテクチャ設計の基礎）</h5>
        ・<a href="https://aws.amazon.com/jp/aws-ten-reasons/" target="blank">AWS の クラウドが選ばれる 10 の理由</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- AWS のクラウドがなぜ選ばれているのかを分かり易くまとめた資料です。稟議などの申請の際にもご活用頂ける資料かと存じます。<br/>
        ・<a href="http://api.open-notify.org/iss-now.json" target="blank">iss-now.json</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 国際宇宙ステーションの緯度経度を知る API。API がどんなものなのかをイメージを掴む為に紹介しています。<br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202209/way-to-operate-api/" target="blank">AWS の API を理解しよう !　 初級編 ~ API の仕組みと利用方法を理解しよう</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 上記の API のイメージと併せて T & C トレーナー記載の API に関するブログ記事を読んで頂くとより理解が深まります。<br/>
        ・<a href="https://aws.amazon.com/jp/compliance/data-center/data-centers/" target="blank">AWS のデータセンター</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/global-infrastructure/" target="blank">グローバルインフラストラクチャ</a><br/>
        ・<a href="https://aws.amazon.com/jp/architecture/well-architected/" target="blank">AWS Well-Architected</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- AWS とお客様によるノウハウを集約したベストプラクティス集です。<br/>
        ・<a href="https://dev.classmethod.jp/articles/aws-well-architected-guide2022/" target="blank">【外部】AWS Well-Architected ドキュメントが読みやすくなりました！！（AWS Well-Architected ドキュメントの歩き方2022）</a><br/>
        <hr/>
        <h5>モジュール2（アカウントのセキュリティ）</h5>
        ・<a href="https://aws.amazon.com/jp/register-flow/" target="blank">AWS アカウント作成の流れ</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/best-practices.html" target="blank">IAM でのセキュリティのベストプラクティス</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/systematic-approach-for-least-privileges-jp/" target="blank">最小権限実現への4ステップアプローチ 前編</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/introducing-s3-security-best-practices-1/" target="blank">Amazon S3 セキュリティベストプラクティスの実践（権限管理のポリシー) – 前編</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/reference_policies_evaluation-logic.html" target="blank">ポリシーの評価論理</a><br/>
        ・<a href="https://dev.classmethod.jp/articles/organizations-scp-inheritance/" target="blank">【外部】[AWS Organizations] SCP(サービスコントロールポリシー)の継承の仕組みを学ぼう</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/managing-the-multi-account-environment-using-aws-organizations-and-aws-control-tower/" target="blank">AWS Organizations と AWS Control Tower を使ったマルチアカウント管理</a><br/>
        <hr/>
        <h5>モジュール3（ネットワーク 1）</h5>
        ・<a href="https://aws.amazon.com/jp/premiumsupport/knowledge-center/vpc-ip-address-range/" target="blank">Amazon VPC の IPv4 CIDR ブロックを変更するにはどうすればよいですか?</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20201021_AWS-BlackBelt-VPC.pdf" target="blank">[AWS Black Belt Online Seminar] Amazon Virtual Private Cloud(VPC)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- VPC にターゲットを絞った図説入りの分かりやすい資料です。（資料自体は少々前のものとなります為、UI 等の変更は御座います。）<br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/10-things-network.pdf" target="blank">AWS のネットワークで知っておくべき10のこと</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- モジュール10の「ネットワーク 2」の内容にも踏み込んだ Advanced な内容の資料です。ネットワークの Deep な部分に触れたい場合にはオススメです。<br/>
        <hr/>
        <h5>モジュール4（コンピューティング）</h5>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20200825_BlackBelt_EC2imagebuilder.pdf" target="blank">[AWS Black Belt Online Seminar] EC2 Image Builder</a><br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2021/summit-online/AWS-13_AWS_Summit_Online_2021_CMP01.pdf#page=14" target="blank">Amazon EC2ことはじめ　～あらゆるワークロードに対応する豊富な選択肢とコスト最適化オプション～ (P. 14)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 汎用の「M」を基準とした考え方でワークロードに応じてどう選択して行くか記載された資料です。<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- モジュール4後半部分の料金プランの選択目安に関して P.37 より記載がありますので併せてご参照下さい。<br/>
        ・<a href="https://gihyo.jp/article/2022/12/mitene-03aws-ri-sps" target="blank">【外部】『家族アルバム みてね』に学ぶ、AWSのReserved InstancesとSavings Plansの勘所</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- オンデマンド以外をご活用頂いているユースケースとして分かりやすい事例です。<br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/how-dena-succesfully-applied-ec2-spot-on-production-and-reference-architecture-using-containers/" target="blank">株式会社ディー・エヌ・エー様におけるEC2スポットインスタンスの大規模活用のための工夫とコンテナ技術を用いた設計例の紹介</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 最大 90% の割引料金でご利用頂けるスポットインスタンスを適用率 100% で実現した取り組みのご紹介記事です。<br/>
        <hr/>
        <h5>モジュール5（ストレージ）</h5>
        ・<a href="https://s3-accelerate-speedtest.s3-accelerate.amazonaws.com/en/accelerate-speed-comparsion.html" target="blank">Amazon S3 Transfer Acceleration Speed Comparison</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Amazon S3 のアップロード効率を上げる機能である Transfer Acceleration の体験です。（全ての結果が出るまでに少々時間がかかります）<br/>
        ・<a href="https://aws.amazon.com/jp/solutions/case-studies/tv-tokyo/" target="blank">株式会社テレビ東京</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Glacier を利用してストレージコストを削減した事例です。<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AWSEC2/latest/UserGuide/ebs-volumes-multi.html" target="blank">Amazon EBS マルチアタッチを使用した複数のインスタンスへのボリュームのアタッチ</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- EBS でマルチアタッチを行いたい場合の考慮事項と制限事項です。<br/>
        ・<a href="https://aws.amazon.com/jp/solutions/case-studies/kddi_2022/" target="blank">AWS 導入事例：KDDI株式会社</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 252 台の Snowball Edge を利用して 25PB の大容量データ移行を約 2 年で完遂した事例です。<br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20210316_AWSBlackBelt2021_AWS-DataSync-v1.pdf" target="blank">[AWS Black Belt Online Seminar] AWS DataSync (顧客事例 P. 82 ～ 84)</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/choose-filestorageservice/" target="blank">AWS ファイルストレージサービスの選び方</a><br/>
        <hr/>
        <h5>モジュール6（データベースサービス）</h5>
        ・<a href="https://d1.awsstatic.com/events/jp/2021/summit-online/AWS-10_AWS_Summit_Online_2021_DAT01.pdf#page=22" target="blank">今日からはじめる︕AWS のデータベースと最適なサービスの選び方 (P. 22)</a><br/>
        ・<a href="https://aws.amazon.com/jp/premiumsupport/knowledge-center/rds-mysql-high-replica-lag/" target="blank">Amazon RDS for MySQL のレプリカの遅延が大きい場合はどのように解決すればよいですか?</a><br/>
        ・<a href="https://www.youtube.com/watch?v=16RYHfe89WY" target="blank">Amazon DynamoDB Deep Dive | AWS Summit Tokyo 2019</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/Session%204%20-%2020200825_CyberZ_OPENRECTV_Purpose-Built-Databases-Week-v2.pdf#page=28" target="blank">OPENREC.tv における Amazon DynamoDB を用いたスケーラブルなアプリケーション設計 (P. 28)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- DynamoDB のオンデマンドモード（スパイクに強い）を活用してコストも押さえている事例。設計の一例として。<br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/how-amazon-dynamodb-supported-zozotowns-shopping-cart-migration-project/" target="blank">ZOZOTOWN のショッピングカート移行プロジェクトを支えた Amazon DynamoDB</a><br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2017/summit/slide/D3T5-3.pdf" target="blank">サーバレスアーキテクチャで実現した『M-1グランプリ』敗者復活戦投票システム</a><br/>
        ・<a href="https://cloud.watch.impress.co.jp/docs/news/1173107.html" target="blank">AWSがデータベースのクラウド移行支援策を説明、住信SBIネット銀行の事例紹介も</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20170919_AWS-BlackBelt-DMS.pdf#page=19" target="blank">【AWS Black Belt Online Seminar】AWS Database Migration Service (P. 19)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- データベースの移行を検討される際にどのように意思決定すれば良いのかの参考となるチャートです。<br/>
        <hr/>
        <h5>モジュール7（モニタリングとスケーリング）</h5>
        ・<a href="https://www.youtube.com/watch?v=9MNMGqnYo68" target="blank">AWS で実現する攻めのシステムモニタリング | AWS Summit Tokyo 2019</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- クラウドならではの監視設計や AWS サービスを利用してどのようにモニタリングを行っていくかの勘所を押さえた動画です。<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/awscloudtrail/latest/userguide/cloudwatch-alarms-for-cloudtrail.html" target="blank">CloudTrail イベントの CloudWatch アラームの作成: 例</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- CloudTrail / CloudWatch Alarm の良くある連携例を記載したドキュメントです。<br/>
        ・<a href="https://pages.awscloud.com/JAPAN-event-OE-Hands-on-for-Beginners-Auto_Scaling-2022-reg-event.html?trk=aws_introduction_page" target="blank">AWS Hands-on for Beginners　Amazon EC2 Auto Scaling スケーリング基礎編</a><br/>
        ・<a href="https://speakerdeck.com/track3jyo/startup-monitoring-aws2022" target="blank">~スタートアップの人たちに捧ぐ~ 監視再入門 in AWS</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- スタートアップの規模に限らず様々な企業様で活用出来る監視の勘所を押さえた良資料です。<br/>
        ・<a href="https://aws.amazon.com/jp/premiumsupport/knowledge-center/potential-account-compromise/" target="blank">AWS アカウントの不正なアクティビティに気付いた場合はどうすればよいですか?</a><br/>
        <hr/>
        <h5>モジュール8（オートメーション）</h5>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20200826_AWS-BlackBelt_AWS-CloudFormation.pdf#page=82" target="blank">[AWS Black Belt Online Seminar] AWS CloudFormation (P. 82)</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AWSCloudFormation/latest/UserGuide/working-with-templates-cfn-designer-walkthrough-createbasicwebserver.html" target="blank">チュートリアル: AWS CloudFormation デザイナー を使用して基本的なウェブサーバーを作成する</a><br/>
        ・<a href="https://aws.amazon.com/jp/solutions/case-studies/honda/" target="blank">AWS 導入事例：本田技研工業株式会社</a><br/>
        ・<a href="https://aws.amazon.com/jp/quickstart/" target="blank">AWS クイックスタート</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 2022/10/25 確認時点では当リンクが AWS の別ページにリダイレクトすることを確認しております。一旦は残しておきますが、基本は👇のリンクから CloudFormation のテンプレートを辿って下さい。<br/>
        ・<a href="https://aws.amazon.com/jp/solutions/" target="blank"> AWS ソリューションライブラリ</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 👆のクイックスタートのリダイレクト先でこちらからも CloudFormation のテンプレートを入手できるようです。適宜「ソリューションライブラリ」の方からダウンロード下さい。<br />
        ・<a href="https://aws.amazon.com/jp/architecture/" target="blank">AWS アーキテクチャセンター</a><br/>
        ・<a href="https://aws.amazon.com/jp/cloudformation/resources/templates/" target="blank">AWS CloudFormation のテンプレート</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-Black-Belt_2023_AWS-SystemsManager-Overview_v1.pdf" target="blank">AWS Systems Manager Overview</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-20_CloudOperations_KMD14.pdf" target="blank">クラウドのシステム運用最前線︕上手く使いこなし運用を楽にしよう。</a><br/>
        <hr/>
        <h5>モジュール9（コンテナ）</h5>
        ・<a href="https://aws.amazon.com/jp/getting-started/hands-on/break-monolith-app-microservices-ecs-docker-ec2/" target="blank">モノリシックアプリケーションをマイクロサービスに分割する</a><br/>
        <hr/>
        <h5>モジュール10（ネットワーク 2）</h5>
        ・<a href="https://aws.amazon.com/jp/directconnect/partners/" target="blank">AWS Direct Connect デリバリーパートナー</a><br/>
        <hr/>
        <h5>モジュール11 a / b（サーバーレス）</h5>
        [11 a] SQS / SNS など<br/>
        ・<a href="https://calculator.aws/#/createCalculator/Lambda" target="blank">設定 AWS Lambda (Calculator)</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/startup/techblog-microservices-introduction/" target="blank">スタートアップのためのマイクロサービス入門</a><br/>
        ・<a href="https://d0.awsstatic.com/events/jp/2017/summit/devday/D2T8-5.pdf" target="blank">Sansanがメッセージング (SQS) でスケーラビリティを手に入れた話</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202206/master-asynchronous-execution-01/" target="blank">非同期処理を使いこなそう ! - 第 1 回 非同期処理ってなんだろう ? -</a><br/>
        ・<a href="https://speakerdeck.com/pikosan0000/sqs-lambda-devday2022" target="blank">SQS + Lambda という⾮同期処理 ⻩⾦パターン再⼊⾨</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202401/sqs-process-duplication/" target="blank">Amazon SQS と処理の重複　前編 ~ 可視性タイムアウトの役割</a><br/>
        ・<a href="https://aws.amazon.com/jp/getting-started/hands-on/send-fanout-event-notifications/" target="blank">ファンアウトイベント通知を送信する</a><br/>
        ・<a href="https://aws.amazon.com/jp/microservices/" target="blank">マイクロサービス</a><br/>
        ・<a href="https://rheb.hatenablog.com/entry/microservices_messaging" target="blank">【外部】マイクロサービスとメッセージングのなぜ [概要編]</a><br/>
        [11 b] Lambda / API Gateway / Step Functions など<br/>
        ・<a href="https://aws.amazon.com/jp/solutions/case-studies/asahi-shimbun/" target="blank">AWS 導入事例：株式会社 朝日新聞社</a><br/>
        <hr/>
        <h5>モジュール12（エッジサービス）</h5>
        ・<a href="https://d1.awsstatic.com/legal/AmazonRoute53SLA/Amazon%20Route%2053%20Service%20Level%20Agreement%20-%20Japanese%20(2018-11-21).pdf" target="blank">Amazon Route 53 サービスレベルアグリーメント</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20191105_AWS_Blackbelt_Route53_Hosted_Zone_A.pdf#page=42" target="blank">[AWS Black Belt Online Seminar] Amazon Route 53 Hosted Zone (P. 42 ～ P. 49)</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/whats-new/2022/06/amazon-route-53-ip-based-routing-dns-queries/" target="blank">Amazon Route 53 が DNS クエリの IP ベースのルーティングを発表</a><br/>
        <hr/>
        <h5>モジュール13（バックアップと復旧）</h5>
        ・<a href="https://aws.amazon.com/jp/blogs/startup/techblog-well-architected-reliability-1/" target="blank">Well-Architected for Startups -信頼性の柱- 導入編</a><br/>
        ・<a href="https://dev.classmethod.jp/articles/aws-managed-database-backups/" target="blank">【外部】AWSの各データベースサービスのバックアップ種類をまとめてみる</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/disaster-recovery-dr-architecture-on-aws-part-1-strategies-for-recovery-in-the-cloud/" target="blank">AWS でのディザスタリカバリ (DR) アーキテクチャ、パートI：クラウドでのリカバリの戦略</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/disaster-recovery-dr-architecture-on-aws-part-ii-backup-and-restore-with-rapid-recovery/" target="blank">AWS のディザスタリカバリ (DR) アーキテクチャ、パート II: 迅速なリカバリによるバックアップと復元</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/disaster-recovery-dr-architecture-on-aws-part-iii-pilot-light-and-warm-standby/" target="blank">AWS のディザスタリカバリ (DR) アーキテクチャ、パート III: パイロットライトとウォームスタンバイ</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/disaster-recovery-dr-architecture-on-aws-part-iv-multi-site-active-active/" target="blank">AWS のディザスタリカバリ (DR) アーキテクチャ、パート IV: マルチサイトアクティブ/アクティブ</a><br/>
        <hr/>
        <h5>その他（順不同）</h5>
        ----- 【トレーニング情報】Architecting on AWS 以外のトレーニング（分野）にご興味をお持ち頂いた方へ<br/>
        ・<a href="https://aws.amazon.com/jp/training/classroom/" target="blank">AWS クラスルームトレーニングの概要</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202203/way-to-choose-training/" target="blank">AWS 認定インストラクターによる有償トレーニングコースの選び方</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202204/what-is-training-benefit/" target="blank">AWS 有償トレーニングのメリットってなんだろう</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202205/way-to-enjoy-training/" target="blank"> AWS 有償トレーニングをもっと楽しむために</a><br/>
        ----- 【各種資料】今後の学習に役立つ各種資料集<br/>
        ・<a href="https://aws.amazon.com/jp/aws-jp-introduction/aws-jp-webinar-level-100/" target="blank">AWS 初心者向け資料</a><br/>
        ・<a href="https://aws.amazon.com/jp/aws-jp-introduction/aws-jp-webinar-service-cut/" target="blank">AWS サービス別資料</a><br/>
        ・<a href="https://www.youtube.com/watch?v=FbnneJ17TA0" target="blank">Architecting for the Cloud 2019 -クラウドにおけるアーキテクチャの設計原則 | AWS Summit Tokyo 2019 (Youtube)</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/how-aws-powered-prime-day-2024-for-record-breaking-sales/" target="blank">AWS がプライムデー 2024 を強化して売上新記録を達成した方法</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 今回のコースで学んでいるサービス達がプライムデーでどれだけ活躍しているのか性能指標を記載したブログ記事です。<br/>
        ----- 【アーキテクチャ図】グループディスカッションでも描いて頂いたアーキテクチャ図の作成のヒント<br/>
        ・<a href="https://aws.amazon.com/jp/architecture/icons/" target="blank">AWS アーキテクチャアイコン</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202204/way-to-draw-architecture/" target="blank">AWS のアーキテクチャ図を描きたい ! でもどうすれば良いの ?</a><br/>
        ----- 【ハンズオン】<br/>
        ・<a href="https://aws-samples.github.io/jp-contents-hub/" target="blank">AWS 日本語ハンズオン</a><br/>
        ----- 【料金関連】AWS サービス周りのまずは料金を知りたい場合<br/>
        ・<a href="https://calculator.aws/#/" target="blank">AWS 料金見積りツール</a>
        <hr/>
      </div>
      <hr class="koji"/>
      <h3 id="groupwork" class="anchor">グループワーク</h3>
      <div class="box">
        <h5>グループワーク資料</h5>
        <p>
          <a href="https://arch-appendix.s3.ap-northeast-1.amazonaws.com/ArchitectingOnAWS_GroupDiscussion.pdf" target="blank">グループワーク資料 (PDF)</a>
        </p>
      </div>
      <hr class="koji"/>
      <h3 id="survey" class="anchor">アンケート</h3>
      <div class="box">
        <h5>アンケートの入力</h5>
        <ol>
          <li><a href="https://www.aws.training">https://www.aws.training</a> へアクセス</li>
          <li>「サインイン」※一般、もしくはAPNパートナー　でサインイン ボタンが異なります</li>
          <li>アカウント > トレーニングデータ　をクリック</li>
          <li><b>アーカイブ済み</b> タブ　で受講したトレーニングを表示</li>
          <li><b>評価</b>　をクリック</li>
          <li>アンケートとコメントを入力</li>
        </ol>
        <img src="survey.png" />
      </div>
      <hr class="koji"/>
      <h3 id="faq" class="anchor">FAQ</h3>
      <div class="box">
        <h5>トレーニングテキスト</h5>
        Q. トレーニングテキストはいつまで参照できますか？<br/>
        &nbsp;&nbsp;・ダウンロードから720日間参照できます。<br/><br/>
        Q. トレーニングテキストは印刷できますか？<br/>
        &nbsp;&nbsp;・Bookshelf というアプリケーションを利用することで印刷が可能です。ただし、一度に印刷できるのは 50 ページの制限があります。無断配布は禁じられております。<br/>
        &nbsp;&nbsp;・<a href="http://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">Bookshelfの入手方法はこちら (PDF)</a><br/>
        &nbsp;&nbsp;※👆の資料のコードによる引き換えは現在方式として（当コースの場合）運用が変更されております。前述の「受講者ポータル」より「受講者ガイド」および「ラボガイド」を引き換え下さい。（要 Bookshelf 登録）
        <hr/>
        <h5>Rocket Chat</h5>
        Q. チャットの内容はいつでも参照できますか？<br/>
        &nbsp;&nbsp;・いいえ、トレーニング最終日の翌日を目処に削除します。
      </div>
    </main>
  );
};

export default MainContents;
